import {useCallback, useEffect} from 'react';
import axios from 'services/axios';
import type {AxiosRequestConfig} from 'axios';
import {Routes, Route, useLocation, Link, Navigate} from 'react-router-dom';
import {styled} from '@mui/system';
import {useTranslation} from 'react-i18next';
import {
  AERQNavigation,
  ProfileChangedFlyout,
  DemoModeFlyout,
  OrganizationSelector,
} from '@control-tower/aerq-navigation-library';
import {usePreferencesState} from '@control-tower/aerq-navigation-library/dist/auth/state/Preferences/context';
import {hideLoadingOverlay, showLoadingOverlay} from 'utils/toggleLoadingOverlay';
import {AuthorizationError} from '@control-tower/aerq-navigation-library';
import {KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL, USER_MANAGEMENT_WEB_API_URL} from 'common/constants';
import {setupAuthorization} from 'services';
import useNavigate from 'common/hooks/useNavigate';
import i18n from 'i18n';
import Messages from './Messages';
import {SnackbarProvider} from 'notistack';
import {addMessage} from 'state/message/actions';
import PageLayout from '../components/PageLayout';
import MediaManagement from './MediaManagement';
import {setupMessageHook} from "../services/interceptors/messages";
import {KeycloakInstance} from "keycloak-js";
import {MediaUploader} from "./MediaUploader";
import {MediaPortal} from "./MediaPortal";
import {DataInsights} from "./DataInsights";

const configAxios = (lang: string) => {
  const setHeader = (config: AxiosRequestConfig) => {
    return {...config, headers: {...config.headers, 'Accept-Language': lang}};
  };

  axios.interceptors.request.use(setHeader);
};

export default function App() {
  const translation = useTranslation();

  useEffect(() => {
    // setup service interceptor
    setupMessageHook(addMessage, translation);
    showLoadingOverlay();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const handleAERQNavigationError = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (error: any) => {
      hideLoadingOverlay();

      if (error instanceof AuthorizationError) {
        // TODO: messagesDispatch(showErrorDialog(401, [{ code: 'NO_PERMISSION' }]));
        console.log('Nav lib error ', 'NO_PERMISSION');
        return;
      }

      if (error.response) {
        const {data, status} = error.response;
        console.log('Nav lib error ', status, data.messages);
        // TODO: messagesDispatch(showErrorDialog(status, data.messages));
        return;
      }

      if (error.request) {
        console.log('Nav lib error ', 404);
        // TODO: messagesDispatch(showErrorDialog(404));

        return;
      }

      console.log('Nav lib error');
      // TODO:  messagesDispatch(showErrorDialog());
    },
    []
  );

  const navigateTo = useNavigate();

  return (
    <AERQNavigation
      keycloakOptions={{
        url: KEYCLOAK_URL,
        realm: KEYCLOAK_REALM as string,
        clientId: KEYCLOAK_CLIENT_ID as string,
      }}
      onAfterSelectProfile={(orgInfo: { profileOrgId?: string }, keycloak: KeycloakInstance) => {
        setupAuthorization(orgInfo, keycloak);
      }}
      notificationServiceURL={''}
      userManagementWebApiURL={USER_MANAGEMENT_WEB_API_URL as string}
      ErrorComponent={undefined}
      onError={handleAERQNavigationError}
      navigateTo={navigateTo}
    >
      <ProfileChangedFlyout/>
      <DemoModeFlyout/>
      <MainContent/>
    </AERQNavigation>
  );
}

const MainContent = () => {
  useEffect(() => {
    hideLoadingOverlay();
  }, []);

  const {preferences} = usePreferencesState();
  useEffect(() => {
    const lang = preferences.language.toLowerCase();
    i18n.changeLanguage(lang);
    localStorage.setItem('appLanguage', lang);

    configAxios(lang);
  }, [preferences.language]);

  const navigateTo = useNavigate();
  const location = useLocation();

  return (
    <AppContainer>
      <SnackbarProvider>
        <Messages/>
        <Routes>
          <Route
            path='/organization-selector'
            element={
              <OrganizationSelector
                onAfterSelectProfile={(orgInfo, keycloak) => {
                  console.log('onAfterSelectProfile');
                  setupAuthorization(orgInfo, keycloak);
                }}
                userManagementWebApiURL={USER_MANAGEMENT_WEB_API_URL as string}
                notificationServiceURL=''
                clientId={KEYCLOAK_CLIENT_ID as string}
                navigateTo={navigateTo}
                location={location}
                Link={Link}
              />
            }
          />
          <Route index element={<Navigate replace to='/main'/>}/>
          <Route element={<PageLayout/>}>
            <Route path='/main' element={<MediaManagement/>}/>
            <Route path='/media-uploader' element={<MediaUploader/>}/>
            <Route path='/media-portal' element={<MediaPortal/>}/>
            <Route path='/data-insights' element={<DataInsights/>}/>
          </Route>
        </Routes>
      </SnackbarProvider>
    </AppContainer>
  );
}

const AppContainer = styled('div')`
  background-color: ${({theme}) => theme.palette.background.common};
  display: flex;
  min-height: 100vh;
  max-width: 100vw;
  flex-direction: column;

  & > div {
    flex-grow: 1;
  }
`;

