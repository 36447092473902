import { useSnackbar } from 'notistack';
import {useDispatch, useSelector} from 'react-redux';
import { useEffect } from 'react';
import { Snackbar } from '@control-tower/aerq-ui-library';
import { Message } from 'common/types';
import {Dispatch, RootState} from 'state/store';
import {removeMessageSuccess} from "../state/message/actions";

export default function Messages() {
  const dispatch: Dispatch = useDispatch();
  const messages = useSelector((state: RootState) => state.message.messages);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    (messages as Array<Message>).forEach(({ key, message, options = {} }: Message) => {
      // Display snackbar using notistack
      enqueueSnackbar(message, {
        ...options,
        content: (contentKey) => {
          const { variant } = options || { variant: undefined };
          return (
            <Snackbar
              onClick={() => {
                closeSnackbar(contentKey);
              }}
              message={message}
              variant={variant}
            />
          );
        },
      });
      // Remove message from state
      dispatch(removeMessageSuccess(key));
    });
  }, [closeSnackbar, dispatch, enqueueSnackbar, messages]);

  return null;
}
