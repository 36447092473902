import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as errorActions from './actions';
import { selectAPIError } from './selectors';

const useErrorActions = () => {
  const dispatch = useDispatch();
  const boundActionCreators = useMemo(() => bindActionCreators(errorActions, dispatch), [dispatch]);

  return boundActionCreators;
};

const useAPIErrors = () => {
  return useSelector(selectAPIError);
};

export { useErrorActions, useAPIErrors };
