import axios from 'axios';
import {GenericMessage} from 'common/types';
import i18next from 'i18next';
import {UseTranslationResponse} from 'react-i18next';
import {Dispatch} from "../../state/store";

function handleGenericMessages(
  addMessage: (variant: string, message: string) => (dispatch: Dispatch) => void,
  genericMessages: Array<GenericMessage>,
  i18n: i18next.i18n
) {
  genericMessages.forEach(({text, level, code}) => {
    const message = i18n.exists(`messages.${code}`) ? i18n.t(`messages.${code}`) : text;

    addMessage(level.toLowerCase(), message);
  });
}

type ResponseType = {
  messages: Array<GenericMessage>;
};

function handleMessages(addMessage: (variant: string, message: string) => (dispatch: Dispatch) => void, responseData: ResponseType, i18n: i18next.i18n) {
  const {messages = []} = responseData;
  const genericMessages = messages.filter((message: GenericMessage) => !('reference' in message));

  handleGenericMessages(addMessage, genericMessages, i18n);
}

export function setupMessageHook(addMessage: (variant: string, message: string) => (dispatch: Dispatch) => void, translation: UseTranslationResponse) {
  const {i18n} = translation;

  return axios.interceptors.response.use(
    ({config, data, headers}) => {
      if (config.responseType === 'blob') {
        return {data, headers};
      }

      handleMessages(addMessage, data, i18n);
      return {...data, headers};
    },
    (error) => {
      // TODO: handle error
      console.log('Axios error ', error);
    }
  );
}
