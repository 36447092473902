import { createReducer } from '@reduxjs/toolkit';
import { Maybe } from 'common/types';
import { Meta } from 'react-async';

import { setError } from './actions';

export type ErrorDataMeta = {
  error?: string;
  path?: string;
  status?: number;
  timestamp?: string;
};

export type ErrorDataV2Message = {
  message: string;
  messageKey: string;
};

export type ErrorDataV2Meta = {
  messages: Array<ErrorDataV2Message>;
  httpStatus?: number;
  traceId?: string;
};

export type ErrorMeta = {
  data?: ErrorDataMeta | ErrorDataV2Meta;
  status?: number;
};

export type APIErrorMeta = {
  error?: ErrorMeta;
  meta?: Meta | undefined;
};

export type ErrorState = {
  api?: Maybe<APIErrorMeta>;
};

const initialState = (): ErrorState => ({
  api: undefined,
});

export default createReducer(initialState, (builder) => {
  builder.addCase(setError, (state, action) => {
    state.api = { ...action.payload } as Maybe<APIErrorMeta>;
  });
});
