import {Container} from "@mui/material";
import {TableauViz} from "../components/TableauViz";

export function DataInsights() {
  return (
    <Container sx={{marginY: '1rem'}}>
      <TableauViz
        tableauReportUrl={'https://tableau.ifesw.com/views/FinalMediaWidgetVer1MediaPortalEmbeddedver/MediaPortalMediaWidget'}
        tabTitle={'Media Insights'}
      />
    </Container>
  )
}