import { Suspense } from 'react';
import { Provider } from 'react-redux';
import App from './views/App';
import { useLayoutEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Loadingindicator } from '@control-tower/aerq-ui-library';
import { hideNeutralLoadingOverlay } from 'utils/toggleLoadingOverlay';
import store from 'state/store';

function Root() {
  useLayoutEffect(() => {
    hideNeutralLoadingOverlay();
  }, []);

  return (
    <Suspense fallback={<Loadingindicator />}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </Suspense>
  );
}

export default Root;
