import { useEffect, useMemo } from 'react';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAPIErrors } from 'state/error/hooks';
import { ReactComponent as RightIcon } from 'assets/svg/right-arrow.svg';
import { hideLoadingOverlay } from 'utils/toggleLoadingOverlay';
import { CustomButton, Label } from '@control-tower/aerq-ui-library';
import { ErrorDataMeta } from 'state/error/reducer';

const ErrorPage = () => {
  const apiErrors = useAPIErrors();

  const { t } = useTranslation();

  useEffect(() => hideLoadingOverlay(), []);

  const statusCode = useMemo(() => {
    const data = apiErrors?.error?.data;
    if (!data) return '';

    if ('messages' in data) return data.httpStatus;

    return data.status;
  }, [apiErrors]);

  const messages = useMemo(() => {
    const data = apiErrors?.error?.data;
    if (!data) return [];

    if ('messages' in data) return data.messages.map((message) => message.message);

    return (apiErrors?.error?.data as ErrorDataMeta).error ? [(apiErrors?.error?.data as ErrorDataMeta).error] : [];
  }, [apiErrors]);

  return (
    <Layout>
      <Title title={t('error.api.title') as string} type='primary' variant='h2' fontSize='XXXXLarge' />
      <StatusCode title={statusCode} type='grey' color='600' fontSize='XLarge' fontWeight='Medium' />

      {messages.map((message, i) => (
        <Message
          key={`message-${i + 0}`}
          title={message}
          type='grey'
          color='400'
          fontSize='Normal'
          fontWeight='Medium'
        />
      ))}

      <Button
        onClick={() => {
          window.location.href = window.location.origin;
        }}
        variant='outlined'
        color='secondary'
        endIcon={<RightIcon />}
      >
        {t('error.label_home_page') as string}
      </Button>
    </Layout>
  );
};

const Layout = styled('div')`
  margin: 7% 0;
  align-self: left;
  margin-top: 80px;
  text-align: left;
  margin-left: 165px;
  margin-bottom: 24px;
`;

const Title = styled(Label)(({ theme }) => ({
  margin: '8px 0',
  letterSpacing: '1px',
  color: theme.palette.primary.main,
  display: 'inline-block',
  background: 'linear-gradient(90deg, #F59601 -5%, #CA0029 100%)',
  '-webkit-background-clip': 'text',
  textFillColor: 'transparent',
}));

const StatusCode = styled(Label)(({ theme }) => ({
  marginTop: theme.spacing(2),
  lineHeight: '28px',
  letterSpacing: '1px',
}));

const Message = styled(Label)(({ theme }) => ({
  marginTop: theme.spacing(2),
  lineHeight: '28px',
  letterSpacing: '1px',
}));

const Button = styled(CustomButton)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

export default ErrorPage;
