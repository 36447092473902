import React, {ReactNode} from "react";
import {Label} from "@control-tower/aerq-ui-library";
import {Box, Paper, Typography} from "@mui/material";
import {Link} from "react-router-dom";

interface IProps {
  disabled?: boolean,
  link: string,
  title: string,
  text: string
  icon: ReactNode
}

export function FeatureTile(props: IProps) {

  const {disabled, link, title, text, icon} = props;

  if(disabled) return null;

  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <Paper sx={{
        width: "20rem",
        minHeight: '12.5rem',
        padding: '2rem',
        borderRadius: 0,
        cursor: 'pointer'
      }
      } elevation={2}>
        <Box display={'flex'} sx={{alignItems: 'center', marginBottom: '1rem'}}>
          {icon}
          <Label
            sx={{marginLeft: '1rem'}}
            title={title}
            color="black"
            type="common"
            fontWeight="Semibold"
          />
        </Box>
        <Typography>
          {text}
        </Typography>
      </Paper>
    </Link>
  )
}