import {Label} from "@control-tower/aerq-ui-library";
import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/system";

// @ts-ignore
const {tableau} = window;

interface IProps {
  tableauReportUrl: string
  tabTitle: string
}

export function TableauViz(props: IProps) {

  const {tableauReportUrl, tabTitle} = props;
  const [viz, setViz] = useState(null);

  function initViz() {

    const options = {
      device: 'desktop'
    }

    let containerDiv = document.getElementById(tabTitle);
    setViz(new tableau.Viz(containerDiv, tableauReportUrl, options));
  }

  useEffect(initViz, [tabTitle, tableauReportUrl]);

  return (
    <>
      <Box display={'flex'} sx={{justifyContent: 'space-between', alignItems: 'center'}}>
        <Label mb={'1rem'}
               variant='h1'
               type='gradient'
               color='main'
               fontWeight='Medium'
               fontSize={'XXXXXLarge'}>
          {tabTitle}
        </Label>
      </Box>
      <ReportBox id={tabTitle}>
      </ReportBox>
    </>
  )
}
const ReportBox = styled(Box)(({theme}) => ({
  '& iframe': {
    margin: 'auto'
  }
}));