import type { KeycloakInstance } from 'keycloak-js';
import type { AxiosRequestConfig } from 'axios';
import { getToken } from '@control-tower/aerq-navigation-library';
import axios from '../axios';

type SetupAuthorization = (organizationInfo: { profileOrgId?: string }, keycloak?: KeycloakInstance) => void;

const setupAuthorization: SetupAuthorization = (organizationInfo = {}, keycloak) => {
  const id = axios.interceptors.request.use(
    async ({ authRequired, ...requestConfig }: AxiosRequestConfig & { authRequired?: boolean }) => {
      const additionalHeaders = {};

      if (authRequired && keycloak) {
        const authHeader = await getToken(keycloak);
        Object.assign(additionalHeaders, authHeader);
      }

      if (organizationInfo.profileOrgId) {
        Object.assign(additionalHeaders, { 'ControlTower-ProfileId': organizationInfo.profileOrgId });
      }

      return { ...requestConfig, headers: { ...requestConfig.headers, ...additionalHeaders } };
    }
  );

  return () => {
    axios.interceptors.request.eject(id);
  };
};

export default setupAuthorization;
