import { combineReducers, configureStore } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import messageReducer from './message/reducer';
import errorReducer from './error/reducer';
import dataPortalApi from '../services';

const combinedReducers = combineReducers({
  message: messageReducer,
  errors: errorReducer,
  [dataPortalApi.reducerPath]: dataPortalApi.reducer,
});

export type RootState = ReturnType<typeof combinedReducers>;

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: combinedReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(dataPortalApi.middleware),
    preloadedState,
  });

export const store = setupStore();

export type Dispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStore>;

setupListeners(store.dispatch);

export default store;
