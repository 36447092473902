import type { BaseQueryApi, BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import axios from 'services/axios';
import Axios from 'axios';
import { DataPortalAPI } from 'common/types/api';
import { hideLoadingOverlay, showLoadingOverlay } from 'utils/toggleLoadingOverlay';
import { setError } from 'state/error/actions';
import { Maybe } from 'common/types';
import { APIErrorMeta } from 'state/error/reducer';

export interface AxiosBaseQueryArgs<Meta, Response = DataPortalAPI.BaseResponse> {
  meta?: Meta;
  prepareHeaders?: (headers: AxiosRequestHeaders, api: BaseQueryApi) => AxiosRequestHeaders;
  transformResponse?: (response: Response) => unknown;
}

export interface ServiceExtraOptions {
  authRequired?: boolean;
  hideLoadingIndicator?: boolean;
}

const getRequestConfig = (args: string | AxiosRequestConfig) => {
  if (typeof args === 'string') {
    return { url: args };
  }

  return args;
};

let pendingCallNum = 0;

const axiosBaseQuery = <
  Args extends AxiosRequestConfig | string = AxiosRequestConfig,
  Result = unknown,
  DefinitionExtraOptions extends ServiceExtraOptions = Record<string, unknown>,
  Meta = Record<string, unknown>
>({ prepareHeaders, meta, transformResponse }: AxiosBaseQueryArgs<Meta> = {}): BaseQueryFn<
  Args,
  Result,
  unknown,
  DefinitionExtraOptions,
  Meta
> => {
  return async (args, api, extraOptions) => {
    try {
      const requestConfig = getRequestConfig(args);

      if (!extraOptions?.hideLoadingIndicator) {
        if (pendingCallNum === 0) showLoadingOverlay();
        pendingCallNum++;
      }

      const result = await axios({
        ...requestConfig,
        headers: prepareHeaders ? prepareHeaders(requestConfig.headers || {}, api) : requestConfig.headers,
        signal: api.signal,
        ...extraOptions,
      });

      if (!extraOptions?.hideLoadingIndicator) {
        pendingCallNum--;
        if (pendingCallNum === 0) hideLoadingOverlay();
      }

      return { data: transformResponse ? transformResponse(result.data) : result.data };
    } catch (err) {
      if (!Axios.isAxiosError(err)) {
        const error = {
          error: err,
          meta,
        };
        api.dispatch(setError(error as Maybe<APIErrorMeta>));

        return error;
      }

      const error = {
        error: {
          status: err.response?.status,
          data: err.response?.data,
        },
        meta,
      };

      api.dispatch(setError(error as Maybe<APIErrorMeta>));

      return error;
    }
  };
};

export default axiosBaseQuery;
