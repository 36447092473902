import {Label} from '@control-tower/aerq-ui-library';
import React from "react";
import {Box, Stack} from "@mui/material";
import {FeatureTile} from "../components/tiles/FeatureTile";
import {tileTexts} from "../components/tiles/tiletexts";

export default function MediaManagement() {

  return (
    <Box sx={{padding: '4rem 8rem'}}>
      <Label
        title="Media Management"
        color="black"
        type="common"
        fontWeight="Normal"
      />
      <Stack sx={{marginTop: '3rem'}} direction={'row'} spacing={5}>
        <FeatureTile
          link={'/media-portal'}
          title={tileTexts.mediaPortal.title}
          text={tileTexts.mediaPortal.text}
          icon={tileTexts.mediaPortal.icon}/>
        <FeatureTile
          link={'/media-uploader'}
          disabled={true}
          title={tileTexts.mediaUploader.title}
          text={tileTexts.mediaUploader.text}
          icon={tileTexts.mediaUploader.icon}/>
        <FeatureTile
          link={'/data-insights'}
          title={tileTexts.dataInsights.title}
          text={tileTexts.dataInsights.text}
          icon={tileTexts.dataInsights.icon}/>
      </Stack>
    </Box>
  );
}
