declare global {
  interface Window {
    _env_: {
      REACT_APP_API_BASE_URL?: string;
      REACT_APP_KEYCLOAK_URL?: string;
      REACT_APP_KEYCLOAK_REALM?: string;
      REACT_APP_KEYCLOAK_CLIENT_ID?: string;
      REACT_APP_USER_MANAGEMENT_WEB_API_URL?: string;
      REACT_APP_DATA_PORTAL_URL: string;
      REACT_APP_ENV_LABEL?: string;
      REACT_APP_TCP_URL?: string;
    };
  }
}

const API_BASE_URL: string =
  (window._env_ ? window._env_.REACT_APP_API_BASE_URL : process.env.REACT_APP_API_BASE_URL) || '';

const KEYCLOAK_URL: string | undefined = window._env_
  ? window._env_.REACT_APP_KEYCLOAK_URL
  : process.env.REACT_APP_KEYCLOAK_URL;
const KEYCLOAK_REALM: string | undefined = window._env_
  ? window._env_.REACT_APP_KEYCLOAK_REALM
  : process.env.REACT_APP_KEYCLOAK_REALM;
const KEYCLOAK_CLIENT_ID: string | undefined = window._env_
  ? window._env_.REACT_APP_KEYCLOAK_CLIENT_ID
  : process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
const USER_MANAGEMENT_WEB_API_URL: string | undefined = window._env_
  ? window._env_.REACT_APP_USER_MANAGEMENT_WEB_API_URL
  : process.env.REACT_APP_USER_MANAGEMENT_WEB_API_URL;
const ENV_LABEL: string | undefined = window._env_ ? window._env_.REACT_APP_ENV_LABEL : process.env.REACT_APP_ENV_LABEL;
const TCP_URL: string | undefined = window._env_
  ? window._env_.REACT_APP_TCP_URL
  : process.env.REACT_APP_TCP_URL;

export {KEYCLOAK_URL, KEYCLOAK_REALM, KEYCLOAK_CLIENT_ID, API_BASE_URL, USER_MANAGEMENT_WEB_API_URL, ENV_LABEL, TCP_URL };