import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosRequestHeaders } from 'axios';
import {AirlineMeta, DataPortalAPI} from 'common/types/api';
import axiosBaseQuery from './axiosBaseQuery';

export const dataPortalApi = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({
    prepareHeaders(headers) {
      return { ...headers } as AxiosRequestHeaders;
    }
  }),
  endpoints: (builder) => ({
    getTestData: builder.query<DataPortalAPI.TestResponse, void>({
      query: () => '/jokes/random',
      extraOptions: {
      },
    }),
    getAirlineList: builder.query<{airlines: AirlineMeta[]}, void>({
      query: () => '/airlines',
      extraOptions: {
        authRequired: true,
      },
    }),
  }),
});

export const {
  useGetTestDataQuery,
  useGetAirlineListQuery,
  endpoints,
} = dataPortalApi;
