import { ReactComponent as MediaPortalIcon } from 'assets/svg/media_portal.svg';
import { ReactComponent as MediaUploaderIcon } from 'assets/svg/media_uploader.svg';
import { ReactComponent as InsightsIcon } from 'assets/svg/insights.svg';

export const tileTexts = {
  mediaPortal: {
    title: "Media Portal",
    text: "Take a quick look at what is onboard and browse through our long list of titles to pick the best for your fleet",
    icon: <MediaPortalIcon width={48} height={48}/>
  },
  mediaUploader: {
    title: "Media Uploader",
    text: "Upload your custom airline passenger announcements, catering menus or other media",
    icon: <MediaUploaderIcon width={48} height={48}/>
  },
  dataInsights: {
    title: "Insights",
    text: "Access rich data analytics for actionable media insights",
    icon: <InsightsIcon width={48} height={48}/>
  }
}